import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from "docz";
import Badge from "./Badge";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "badge"
    }}>{`Badge`}</h1>
    <h2 {...{
      "id": "attributes"
    }}>{`Attributes`}</h2>
    <Props of={Badge} mdxType="Props" />
    <h2 {...{
      "id": "badge-types"
    }}>{`Badge Types`}</h2>
    <ul>
  <li>Complete badge will show a title, subtitles and year.</li>
  <li>Score badge will show a title and a score.</li>
    </ul>
    <h2 {...{
      "id": "complete-badge-basic-usage"
    }}>{`Complete Badge Basic Usage`}</h2>
    <Playground __position={2} __code={'<Badge\n  type=\"complete\"\n  content={{\n    title: \'top rated\',\n    firstSubtitle: \'car insurance agents\',\n    secondSubtitle: \'New York\',\n    year: \'2020\',\n  }}\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Badge,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Badge type="complete" content={{
        title: 'top rated',
        firstSubtitle: 'car insurance agents',
        secondSubtitle: 'New York',
        year: '2020'
      }} mdxType="Badge" />
    </Playground>
    <h2 {...{
      "id": "score-badge-basic-usage"
    }}>{`Score Badge Basic Usage`}</h2>
    <Playground __position={3} __code={'<Badge\n  type=\"score\"\n  content={{\n    title: \'score\',\n    score: \'95\',\n  }}\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Badge,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Badge type="score" content={{
        title: 'score',
        score: '95'
      }} mdxType="Badge" />
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      